.carousel {
  height: 100%;
  flex: 1;
}

.react-multi-carousel-dot button {
  border-color: transparent;
  width: 8px;
  height: 8px;
}

.react-multi-carousel-dot--active button {
  background: #f4d144;
}
