@font-face {
  font-display: block;
  font-family: Yekan;
  font-style: normal;
  font-weight: 100;
  src: url('../fonts/web/ttf/YekanBakh-Thin.ttf') format('woff');
}

@font-face {
  font-display: block;
  font-family: Yekan;
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/web/ttf/YekanBakh-Light.ttf') format('woff');
}

@font-face {
  font-display: block;
  font-family: Yekan;
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/web/ttf/YekanBakh-Regular.ttf') format('woff');
}

@font-face {
  font-display: block;
  font-family: Yekan;
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/web/ttf/YekanBakh-SemiBold.ttf') format('woff');
}

@font-face {
  font-display: block;
  font-family: Yekan;
  font-style: normal;
  font-weight: bold;
  src: url('../fonts/web/ttf/YekanBakh-Bold.ttf') format('woff');
}

@font-face {
  font-display: block;
  font-family: Yekan;
  font-style: normal;
  font-weight: 800;
  src: url('../fonts/web/ttf/YekanBakh-ExtraBold.ttf') format('woff');
}

@font-face {
  font-display: block;
  font-family: Yekan;
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/web/ttf/YekanBakh-Black.ttf') format('woff');
}

@font-face {
  font-display: block;
  font-family: Yekan;
  font-style: normal;
  font-weight: 950;
  src: url('../fonts/web/ttf/YekanBakh-ExtraBold.ttf') format('woff');
}

@font-face {
  font-display: block;
  font-family: 'MaterialCommunityIcons';
  src: url('../../../node_modules/react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf')
    format('truetype');
}
