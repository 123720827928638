/* These styles make the body full-height */
html, body, #root {
    max-width: 480px;
    border-left: solid 0.01rem #0A0A0A;
    height: 100%;
    margin: 0 auto;
    /*width: 100%;*/
    overflow: auto;
    background: #0A0A0A;
}

@viewport {
    orientation: portrait;
}

@media screen and (max-width: 330px) {
    html {
        width: 330px;
        overflow: auto;
    }
}

@media screen and (max-height: 400px) {
    html {
        height: 400px;
        overflow: auto;
    }
}

/* These styles disable body scrolling if you are using <ScrollView> */
body {
    overflow: hidden;
}

/* These styles make the root element full-height */
#root {
    display: flex;
    flex-direction: column;
    user-select: none;
    overflow: hidden;
}

input:focus, textarea:focus, select:focus {
    outline: none;
}

::-webkit-scrollbar {
    width: 1px;
    height: 1px;
}

/* Track */
::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.leaflet-m-icon {
    background: transparent;
    border: none;
    cursor: pointer;
}

#progressSlider :nth-child(2)  {
    display: none;
}
#progressSlider :nth-child(1)  {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}
#progressSlider :nth-child(3)  {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

#inputError  {
    border:1px solid #EB5757;
    border-radius: 12px;
}
